import React from 'react'
import { Helmet } from 'react-helmet'

export type MetatagsProps = {
  meta: {
    metaTitle?: string
    metaDescription?: string
    metaOgtitle?: string
    metaOgdescription?: string
    metaOgimage?: string
    canonical?: string
    noIndex?: string | boolean
  }
  locale?: string
}

export default function Metatags({
  meta,
  locale,
}: MetatagsProps): React.ReactElement {
  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      {!!meta?.metaTitle && <title>{meta.metaTitle}</title>}

      {!!meta?.metaDescription && (
        <meta name="description" content={meta.metaDescription} />
      )}
      {!!meta?.metaOgtitle && (
        <meta property="og:title" content={meta.metaOgtitle} />
      )}
      <meta name="twitter:card" content="summary" />
      {!!meta?.metaOgdescription && (
        <meta property="og:description" content={meta.metaOgdescription} />
      )}
      {!!meta?.metaOgimage && (
        <meta property="og:image" content={`https:${meta.metaOgimage}`} />
      )}
      {!!meta?.canonical && <link rel="canonical" href={meta.canonical} />}
      {!!meta?.noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}
